import { useEffect, useState } from "react";
import ReactNotifications from 'react-notifications-component';
import ChippedArtLogo from "./ChippedArtLogo";
import LoadingOverlay from 'react-loading-overlay';
import "./App.css";
//import { Wallet, Chain, Network } from "mintbase";
import * as nearAPI from "near-api-js";
import styled from "styled-components";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";

import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

var nettouse = 'testnet';
var baseurl = 'demo.chippedart';
//var nearclaimed = false;

var droplinkpresent = false;
var dropclaimed = false;
var registered = false;
var claimcodevalid = true;
var firstrun = true;

var activated = false;





const Container = styled.div`
	text-align: center;
	max-width: 100vw;
`;

const Header = styled.div`
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
`;

const Link = styled.a`
	color: white;
`;

const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Button = styled.button`
	height: 50px;
	margin: 10px;
`;

const DetailsContainer = styled.div`
	max-width: 300px;
`;

const Details = styled.p`
	font-size: 18px;
`;

const Shortbutton = styled.button`
	margin:10px;
	font-size: 18px;
`;

const Label = styled.label`
	font-weight:bold;
`;

function App() {
	
	
	var callingurl = window.location.href;
	if(callingurl.includes(".demo.")){
		nettouse="testnet"
		baseurl = "demo.chippedart";
	}
	else{
		nettouse="mainnet"
		baseurl = "chippedart";
	}
	
	console.log(nettouse)
	
	
	const { connect, keyStores, WalletConnection, utils } = nearAPI;

	
	const config = {
	  networkId: "testnet",
	  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
	  nodeUrl: "https://rpc."+nettouse+".near.org",
	  walletUrl: "https://wallet."+nettouse+".near.org",
	  helperUrl: "https://helper."+nettouse+".near.org",
	  explorerUrl: "https://explorer."+nettouse+".near.org",
	};

	var wallet;
	

	
	const [cookies, setCookie] = useCookies(["claim"]);
	const urlSearchParams = new URLSearchParams(window.location.search);
	const [popupid, setPopUpId] =useState(0);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [details, setDetails] =
    useState<{
		accountId: string;
		balance: string;
		allowance: string;
		contractName: string;
    }>();


	
	const [inpromise, setPleaseWait] = useState(false);
	const [pleasewaittext, setPleaseWaitText] = useState('');
	const [retailerid, setRetailerId] = useState(cookies.retailerid);
	
	const handleRetailerId = (retailerids: string | null) =>
    {
		if(retailerids===null)return;
        console.log(retailerids);
		setRetailerId(retailerids);
		let d = new Date();
		d.setTime(d.getTime() + (365*24*60*60*1000));
		setCookie("retailerid", retailerids, {path: "/", expires: d});		
    }	
	
 const initWallet = async () => {

	const near = await connect(config);
	wallet = new WalletConnection(near,"ChippedArt");
	console.log(wallet.getAccountId());
	
	if(wallet.isSignedIn()){
		console.log('signed in');
		const account = await wallet.account();
		var details = {accountId:"",balance:"",allowance:"",contractName:""};
		if(details!==undefined){
			details.accountId = wallet.getAccountId();
			details.balance = utils.format.formatNearAmount((await account.getAccountBalance()).available,2);
			details.allowance = "";
			details.contractName = "";
			setDetails(details);
			console.log("set details");

		}
		setIsLoggedIn(true);

	}else{
	
		console.log('not signed in');
		setIsLoggedIn(false);
	}


  };

  const handleConnect = async (shouldRequest: boolean) => {

	// connect to NEAR
	const near = await connect(config);
	// create wallet connection
	wallet = new WalletConnection(near,"ChippedArt");
	
	if(wallet.isSignedIn()){
		console.log('signed in');
	}else{
		console.log(wallet);
		wallet.requestSignIn("","ChippedArt");
	}
  };
  


  const handleDisconnect = async () => {
	  
	// connect to NEAR
	const near = await connect(config);

	// create wallet connection
	const wallet = new WalletConnection(near,"ChippedArt");

	await wallet.signOut();
    window.location.reload();
  };
 	
	
	var cc = cookies.claim
	if(urlSearchParams.get("claim")!==undefined){
		if(urlSearchParams.get("claim")!=null){
		cc = urlSearchParams.get("claim")
		setCookie("claim", cc, {path: "/"});
		console.log(cc)
		}
	}
	const [claimcode] = useState(cc);


	const [nearaddress, setNEARAddress] = useState('[Please connect to wallet]');
	const handleNEARAddress = (nearaddress: string | null) =>
    {
		if(nearaddress===null)return;
        console.log(nearaddress);
		setNEARAddress(nearaddress);	
    }



	const getStatus = () =>{
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","status")
		urlpost.append("claimid",claimcode)
		console.log('claimcode - '+claimcode)

		var url = "https://api."+baseurl+".com/api.php"
		fetch(url,{
			method:'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: urlpost
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log(result);
				firstrun=false
				if(result.error!==""){
					claimcodevalid=false;
					setPopUpId(store.addNotification({
						content: MyNotification(result.error,false),
						type: 'warning',                         // 'default', 'success', 'info', 'warning'
						container: 'top-center',                // where to position the notifications
						animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
						animationOut: ["animated", "fadeOut"]   // animate.css classes that's applied
					}))

				}else{
					console.log(result.data)
					var res = JSON.parse(result.data)
					console.log(res)
					droplinkpresent = res.droplink
					dropclaimed = res.dropclaimed
					registered = res.registered
					activated = res.activated


					setNEARAddress(res.nearaddress)

					
				}
			}
		)
	}


	useEffect(() => {
		initWallet();
		console.log('use effect')
		getStatus()

		//if (!wallet) return;
		//wallet.disconnect();
		//if(cookies.claim!==undefined)console.log("Got claim cookie")
	},[]); // eslint-disable-line react-hooks/exhaustive-deps


	

	
	const handleClaimClick = () => {
		console.log(claimcode)
		if(claimcode==='' || claimcode===undefined){
			if(popupid>0)store.removeNotification(popupid)
			setPopUpId(store.addNotification({
				content: MyNotification('Claim code must be specified',false),
				type: 'info',                         // 'default', 'success', 'info', 'warning'
				container: 'top-center',                // where to position the notifications
				animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
				animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
				//dismiss: {
				//  duration: 3000
				//}
			}))
		}else{
			console.log('Calling claim')
			var urlpost = new URLSearchParams();
			urlpost.append("cmd","claim")
			urlpost.append("claimid",claimcode)
			console.log('claimcode - '+claimcode)
			urlpost.append("nearaddress",nearaddress)
			urlpost.append("storename","shared")
			var url = "https://api."+baseurl+".com/api.php"
			fetch(url,{
				method:'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				body: urlpost
			})
			.then(res => res.json())
			.then(
				(result) => {
					console.log(result);
					if(result.error!==""){
						setPopUpId(store.addNotification({
							content: MyNotification(result.error,false),
							type: 'warning',                         // 'default', 'success', 'info', 'warning'
							container: 'top-center',                // where to position the notifications
							animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
							animationOut: ["animated", "fadeOut"]   // animate.css classes that's applied
						}))
					}else{
						setPopUpId(store.addNotification({
							content: MyNotification(result.message,false),
							type: 'warning',                         // 'default', 'success', 'info', 'warning'
							container: 'top-center',                // where to position the notifications
							animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
							animationOut: ["animated", "fadeOut"]   // animate.css classes that's applied
						}))
						//window.location.replace(result.url);
						var res = window.open(result.url);
						var urlpost = new URLSearchParams();
						urlpost.append("cmd","checkdrop")
						urlpost.append("dropurl",result.url)
						var url = "https://api."+baseurl+".com/api.php"
						
						var si = setInterval(function(){
							console.log('checking drop account balance')
							fetch(url,{
							method:'POST',
							headers: {'Content-Type': 'application/x-www-form-urlencoded'},
							body: urlpost
							})
							.then(res => res.json())
							.then((result) => {
								console.log(result)
								if(result.balance===0){
									setTimeout(function(){
										console.log('closing claim window')
										if(!res) return;
										console.log('now')
										res.close()
										//window.location.reload();
										handleConnect(true);
										clearInterval(si)
									},2000)
									
								}
							}
							)
							
						},2000)
														
					}
				}
			)
		}
	}


	const handleActivationClick = () => {
		console.log(claimcode)
		if(claimcode==='' || claimcode===undefined){
			if(popupid>0)store.removeNotification(popupid)
			setPopUpId(store.addNotification({
				content: MyNotification('Claim code must be specified',false),
				type: 'info',                         // 'default', 'success', 'info', 'warning'
				container: 'top-center',                // where to position the notifications
				animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
				animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
				//dismiss: {
				//  duration: 3000
				//}
			}))
		}else{
			
			setPleaseWait(true);
			setPleaseWaitText('Activating code..');
			var urlpost = new URLSearchParams();
			urlpost.append("cmd","activation")
			urlpost.append("claimid",claimcode)
			urlpost.append("retailerid",retailerid)
			console.log('claimcode - '+claimcode)
			var url = "https://api."+baseurl+".com/api.php"
			fetch(url,{
				method:'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				body: urlpost
			})
			.then(res => res.json())
			.then(
				(result) => {
					console.log(result);
					setPleaseWait(false);
					if(result.error!==""){
						setPopUpId(store.addNotification({
							content: MyNotification(result.error,false),
							type: 'warning',                         // 'default', 'success', 'info', 'warning'
							container: 'top-center',                // where to position the notifications
							animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
							animationOut: ["animated", "fadeOut"]   // animate.css classes that's applied
						}))
					}else{
						setPopUpId(store.addNotification({
							content: MyNotification(result.message,false),
							type: 'warning',                         // 'default', 'success', 'info', 'warning'
							container: 'top-center',                // where to position the notifications
							animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
							animationOut: ["animated", "fadeOut"]   // animate.css classes that's applied
						}))
						//window.location.replace(result.url);
						console.log('completed activation')
														
					}
				}
			)
		}
	}






	
	
	
	const MyNotification = (msg: string, okButton: boolean) => {
		return (
			<div style={{
				//display: 'flex',
				backgroundColor: '#ffffff',
				fontFamily: 'Courier New',
				borderRadius: 5,
				color: 'black',
				width: '100%',
				minHeight:'100px',
				margin:'10px',

				
			}}>

				<div style={{padding:'10px'}}>{msg}</div>
				<div>

				<Shortbutton>Cancel</Shortbutton>
				</div>
			</div>
		)
	}

	
	
	return (
	<CookiesProvider>
	  <LoadingOverlay
  active={inpromise}
  spinner
  text={pleasewaittext}
  >
		<Container>
		<ReactNotifications />
		  <Header>
			<LogoContainer>
				<Link href='https://www.chippedart.com'>
					<ChippedArtLogo />
				</Link>
			</LogoContainer>
			<br/>
			
			{claimcodevalid && !activated && (<>
				<table>
					<tr><td><input onChange={ (e) => handleRetailerId(e.target.value) }  value={retailerid} /></td></tr>
					<tr><td><Shortbutton onClick={() => handleActivationClick()}>Activate Registration Card</Shortbutton></td></tr>
				</table>
			
			</>)}
			
			
			{claimcodevalid && !firstrun && activated && (<>
				<table>
					<tbody>
					<tr><td><Label>Claim Code</Label></td></tr>
					<tr><td><Label>{cc}</Label></td></tr>
					
					{droplinkpresent && !registered && (<tr><td><Shortbutton onClick={() => handleClaimClick()}>Claim NEAR Account</Shortbutton></td></tr>)}
					{droplinkpresent && !dropclaimed && false && (<tr><td><Label>NEAR Address</Label></td></tr>)}
					{droplinkpresent && !dropclaimed && false && (<tr><td><input readOnly onChange={ (e) => handleNEARAddress(e.target.value) } value={nearaddress}  /></td></tr>)}

					{registered && (<tr><td>Registered ID:<Label>{nearaddress}</Label></td></tr>)}

					</tbody>
				</table>



				{isLoggedIn && details && (
				  <DetailsContainer>
					<Details>Account: {details.accountId} ({details.balance}N) </Details>
				  </DetailsContainer>
				)}

				<ButtonsContainer>
				  {!isLoggedIn && !registered && (
					<Button onClick={() => handleConnect(true)}>Connect to Wallet</Button>
				  )}
				  {isLoggedIn && (
					<Button onClick={() => handleDisconnect()}>Disconnect from Wallet</Button>
				  )}
				</ButtonsContainer>
			</>)}
		  </Header>
		</Container>
		</LoadingOverlay>
	</CookiesProvider>
	);
}





export default App;
