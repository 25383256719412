import styled from 'styled-components'
import logo from './chipart-logo-white.png'


interface ChippedArtLogoProps {
  width?: number
  height?: number
  color?: string
}

interface ContainerProps {
  width?: number
  color?: string
}

const Container = styled.div`
  width: ${({ width }: ContainerProps) => `${width}px`};
  height: ${({ width }: ContainerProps) => `${width}px`};
`

const defaultProps = {
  width: 120,
  color: 'white',
}

const ChippedArtLogo = ({ width, color }: ChippedArtLogoProps = defaultProps) => {

  const setWidth = width ? width : defaultProps.width

  return (
    <Container width={setWidth}>
     <img src={logo} alt="" width={setWidth}/>
    </Container>
  )
}

export default ChippedArtLogo
